<template>
    <div class="problemFound">
        <header>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="地区：">
                    <el-select @change="getList" v-model="query.areaCode" class="search-input" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in areaList"
                            :key="item.code"
                            :label="item.label"
                            :value="item.code"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <div class="data-update">
                    <el-popover
                        ref="popover"
                        :visible="visible"
                        placement="bottom"
                        :width="340"
                        trigger="click"
                        popper-class="popover"
                    >
                        <template #reference>
                            <div class="update" @click="visible = true">
                                <el-icon><Warning /></el-icon><span>数据更新说明</span>
                            </div>
                        </template>
                        <div class="popover">
                            <div class="popover-title">
                                <div class="title-text">数据来源及数据更新时间说明</div>
                                <el-icon @click="visible = false"><Close /></el-icon>
                            </div>
                            <div class="popover-content">
                                <div class="content-item" v-for="(item, index) in Object.values(timeMap)" :key="index">
                                    {{ index + 1 + '、' + item }}
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </div>
                <el-button @click="exportFile" type="primary" plain
                    ><el-icon class="el-icon--right"><Download /></el-icon>《同心指数评价体系（试行）》下载</el-button
                >
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <span>各地同心指数情况(目前满分{{ query.areaCode === 330000 ? 100 : 90 }}分)</span>
                <!-- <span>各地同心指数情况(目前满分90分)</span> -->
                <div class="export-box">
                    <el-button @click="exportExcel" type="primary">导出本页</el-button>
                    <el-button @click="exportExcel('all')" type="primary">导出全部</el-button>
                </div>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                >
                    <el-table-column type="index" width="70" label="排名">
                        <template #default="{ $index }">
                            <div v-if="$index < 3">
                                <img :src="`/img/rank${$index + 1}.png`" alt="" />
                            </div>
                            <div v-else>
                                {{ $index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="areaName" label="行政区划"> </el-table-column>
                    <el-table-column prop="score" label="总分"> </el-table-column>
                    <el-table-column prop="achievementTitle" label="覆盖度">
                        <el-table-column prop="fgdPydxScore" label="培养对象占比">
                            <template #header>
                                培养对象占比<span class="table-header">({{ scoreMap.pydxScore }}分)</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fgdSzfScore" label="双走访覆盖率">
                            <template #header>
                                双走访覆盖率<span class="table-header">({{ scoreMap.szfScore }}分)</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="achievementTitle" label="培养度">
                        <el-table-column prop="pydJypxScore" label="教育培训覆盖率">
                            <template #header>
                                教育培训覆盖率<span class="table-header">({{ scoreMap.jypxScore }}分)</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="pydSjdlScore" label="实践锻炼参与率">
                            <template #header>
                                实践锻炼参与率<span class="table-header">({{ scoreMap.sjdlScore }}分)</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="achievementTitle" label="使用度">
                        <el-table-column prop="sydScore" label="党外代表人士使用情况">
                            <template #header>
                                党外代表人士使用情况<span class="table-header">({{ scoreMap.sdyMaxScore }}分)</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="achievementTitle" label="影响度">
                        <el-table-column prop="yxdXxxdfzsScore" label="新乡贤带富指数">
                            <template #header>
                                新乡贤带富指数<span class="table-header">({{ scoreMap.xxxdfzsScore }}分)</span>
                            </template></el-table-column
                        >
                        <el-table-column prop="yxdShfwdyclScore" label="社会服务动员参与率">
                            <template #header>
                                社会服务动员参与率<span class="table-header">({{ scoreMap.shfwdyclScore }}分)</span>
                            </template></el-table-column
                        >
                        <el-table-column prop="yxdYzztcldpsScore" label="议政直通车领导批示率">
                            <template #header>
                                议政直通车领导批示率<span class="table-header">({{ scoreMap.yzztcldpsScore }}分)</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="yxdSqmylyvScore" label="社情民意录用率">
                            <template #header>
                                社情民意录用率<span class="table-header">({{ scoreMap.sqmylyvScore }}分)</span>
                            </template></el-table-column
                        >
                        <el-table-column prop="yxdSzjzsScore" label="宗教中国化指数">
                            <template #header>
                                宗教中国化指数<span class="table-header">({{ scoreMap.szjzsScore }}分)</span>
                            </template></el-table-column
                        >
                        <el-table-column prop="yxdNdzwqyzflScore" label="年度执委企业走访率">
                            <template #header>
                                年度执委企业走访率<span class="table-header">({{ scoreMap.ndzwqyzflScore }}分)</span>
                            </template></el-table-column
                        >
                        <el-table-column prop="yxdWqfwbsxlScore" label="为侨服务办事效率">
                            <template #header>
                                为侨服务办事效率<span class="table-header">({{ scoreMap.wqfwbsxlScore }}分)</span>
                            </template></el-table-column
                        >
                    </el-table-column>
                    <el-table-column prop="achievementTitle" label="防控度">
                        <el-table-column prop="yxdFkdScore" label="涉负面信息人数">
                            <template #header>
                                涉负面信息人数<span class="table-header">({{ scoreMap.fkdScore }}分)</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import { regMobile } from '@/utils/common';
import dd from 'gdt-jsapi';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;

const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    areaCode: 330000,
});
const areaList = [
    {
        label: '浙江省',
        code: 330000,
    },
    {
        label: '杭州市',
        code: 330100,
    },
    {
        label: '宁波市',
        code: 330200,
    },
    {
        label: '温州市',
        code: 330300,
    },
    {
        label: '湖州市',
        code: 330500,
    },
    {
        label: '嘉兴市',
        code: 330400,
    },

    {
        label: '绍兴市',
        code: 330600,
    },
    {
        label: '金华市',
        code: 330700,
    },
    {
        label: '衢州市',
        code: 330800,
    },
    {
        label: '舟山市',
        code: 330900,
    },
    {
        label: '台州市',
        code: 331000,
    },
    {
        label: '丽水市',
        code: 331100,
    },
];
let total = ref(0);
let visible = ref(false);
let tableData = ref([]);
let scoreMap = ref({
    pydxScore: '', //覆盖度培养对象占比满分
    szfScore: '', //覆盖度双走访覆盖率满分
    jypxScore: '', //培养度教育培训覆盖率满分
    sjdlScore: '', //培养度实践锻炼参与率满分
    sdyMaxScore: '', //党外代表人士使用情况
    xxxdfzsScore: '', //新乡贤带富指数
    shfwdyclScore: '', //社会服务动员参与率
    yzztcldpsScore: '', //议政直通车领导批示率
    sqmylyvScore: '', //社情民意录用率
    szjzsScore: '', //宗教中国化指数
    ndzwqyzflScore: '', //年度执委企业走访率
    wqfwbsxlScore: '', //  为侨服务办事效率
    fkdScore: '', //涉负面信息人数
});
let timeMap = ref({
    nwFgdpydSjtbsj: '覆盖度、培养度来源于之江同心·党外代表人士全链条服务管理在线重大应用，数据推送时间为',
    nwSydSjtbsj: '使用度来源于之江同心·党外代表人士全链条服务管理在线重大应用，数据推送时间为',
    shfwsjSjtbsj: '社会服务动员参与率来源于社会服务应用，数据推送时间为',
    ztcsjSjtbsj: '议政直通车领导批示率来源于直通车应用，数据推送时间为',
    sqmysjSjtbsj: '社情民意录用率来源于社情民意应用，数据推送时间为',
    ndzwqyzfsjSjtbsj: '年度执委企业走访率来源于浙商在线应用，数据推送时间为',
    qqtbslSjtbsj: '为侨服务办事效率来源于全球通应用，数据推送时间为',
});

function getList() {
    // 每次请求都要重置
    timeMap.value = {
        nwFgdpydSjtbsj: '覆盖度、培养度来源于之江同心·党外代表人士全链条服务管理在线重大应用，数据推送时间为',
        nwSydSjtbsj: '使用度来源于之江同心·党外代表人士全链条服务管理在线重大应用，数据推送时间为',
        shfwsjSjtbsj: '社会服务动员参与率来源于社会服务应用，数据推送时间为',
        ztcsjSjtbsj: '议政直通车领导批示率来源于直通车应用，数据推送时间为',
        sqmysjSjtbsj: '社情民意录用率来源于社情民意应用，数据推送时间为',
        ndzwqyzfsjSjtbsj: '年度执委企业走访率来源于浙商在线应用，数据推送时间为',
        qqtbslSjtbsj: '为侨服务办事效率来源于全球通应用，数据推送时间为',
    };
    proxy.$api.cohesionIndex.getPage(query.value).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records.map((item) => {
                for (const key in item) {
                    if (item[key] === null) {
                        item[key] = '-';
                    }
                }
                return item;
            });
            console.log(Object.keys(scoreMap.value));
            Object.keys(scoreMap.value).map((item, index) => {
                scoreMap.value[item] = tableData.value[0][item];
            });
            Object.keys(timeMap.value).map((item, index) => {
                console.log(timeMap.value[item], item);
                timeMap.value[item] += tableData.value[0][item];
            });

            console.log('timeMap.value', Object.values(timeMap.value));

            total.value = res.data.total;
        }
    });
}
function exportExcel(type) {
    fetch(
        process.env.VUE_APP_API_BASE_URL +
            `/front/admSztzNjlzsDqdfqk/export?page=-1&size=-1&areaCode=${type === 'all' ? '' : query.value.areaCode}`,
        {
            method: 'post',
            headers: {
                Authorization: localStorage.token,
            },
        }
    )
        .then((res) => {
            res.blob().then((blob) => {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(blob);
                var filename = '同心指数.xlsx'; //各地同心指数情况
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            });
        })
        .catch((err) => {
            ElMessage.error('导出失败');
        });
}
function exportFile() {
    let url = `${process.env.VUE_APP_API_FILE_IP}/profile/upload/2023/04/03/同心指数评价体系.pdf`;
    // 判断是dd浏览器还是普通浏览器
    const isDD = regMobile('dingtalkWin');
    if (isDD) {
        dd.openLink({
            url: `${url}?ddtab=true`,
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {});
    } else {
        window.open(url);
    }
}
function search() {
    query.value.page = 1;
    getList();
}
function resize() {
    query.value = {
        page: 1,
        size: 15,
        areaCode: 330000,
    };
    getList();
}

// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});

watch(() => {});
onMounted(() => {
    getList();
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.problemFound {
    height: calc(100% - 32px);
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            display: flex;
            white-space: nowrap;
            .data-update {
                display: flex;
                align-items: center;
                height: 30px;

                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                margin: 0 16px 0 0;
                .update {
                    cursor: pointer;
                }
                .el-icon {
                    margin: 0 6px 0 0;
                    line-height: 13px;
                }
            }
        }
    }

    main {
        margin-top: 16px;
        height: calc(100% - 85px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            // text-align: right;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            .el-button {
                margin-right: 8px;
            }
        }
        .table {
            height: calc(100% - 76px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                padding-top: 10px;
                text-align: right;
            }
        }
        .table-header {
            color: red;
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
    :deep(.el-table th.el-table__cell) {
        text-align: center;
    }
}
.popover {
    .popover-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #0000000f;
        padding: 0 0 8px 0;
        box-sizing: border-box;
        .title-text {
            font-family: PingFangSC-Medium;
            font-weight: 600;
            font-size: 14px;
            color: #333333;
        }
        .el-icon {
            cursor: pointer;
        }
    }
    .popover-content {
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin: 8px 0 0 0;
    }
    .content-item {
        margin: 8px 0 0 0;
    }
}
</style>
